import React from "react";
function About() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-8 mt-4 ">
            <div className="col-sm-12 p-4 about">
              <h1 className="about_text">ABOUT</h1>
              <hr />
              <p>Hello, Dear friends, Welcome to hopiffy also, we are happy you want to know something more
                about our site.</p>
              <p>So, basically, nowadays people are more dependent on online products and services that's why
                we also, take forward a step to help you.</p>
              <p>Our first wish is to provide you with a better solution to solve your problem. So, kindly if you
                don't get any solution then mention it in the comment section</p>
              <p>Also, we are trying to provide fresh & latest content that provides you ideas about all updated
                information that's happening in the world</p>
              <p>In the below section you can get more ideas about our site like our website category and
                content category.</p>
              <p>If you have additional questions or require more information about our About Us Page, do not
                hesitate to contact us through email at hopiffy@gmail.com
              </p>
              <b>What is Our Goal?
              </b>
              <p>There are millions of websites created every day, also, there is much fake content spread on the
                internet.
              </p>
              <p>So, Our main goal is to provide you with 100% Original and Safe content that provides you a
                great and better experience on the world wide web</p>
              <p>We mainly focus on our service and improving it regularly to provide a better user experience to
                all users.
              </p>
              <p>Basically, we focus on the Technology, Animal, Weird, Entertainment, History and Mythology
                niche so, our main priority is to search for new content and present it in front of you to learn
                something new</p>
              <b>What is our Service?
              </b>
              <p>We are mainly focused on the Technology, Animal, Weird, Entertainment, History and Mythology
                category so, we provide related content if you are interested in these categories then you can
                visit daily to get more latest information.</p>
              <p>Hopiffy provides all related information, weird things and surprising facts.
              </p>
              <p>We focus on many other categories and we hope you also like the content of other categories
                that are maintained on our website. So, you can visit our website homepage to know all
                category details you can visit our homepage --hopiffy.com.</p>
              <p>Also, we provide a Notification update service you can join by email and other Social Media
                Platforms, and all Links you can get on the Homepage visit now. hopiffy
              </p>
              <b>About hopiffy
              </b>
              <p>As you can see already we mention what is our goal and Service again we repeat that we
                mainly focus on Technology, Animal, Weird, Entertainment, History, Mythology Category to help
                people.
              </p>
              <p>This Website is Created By hopiffy to help people because many people are still spending hours
                of time to get exact information so, this is the only motive to create hopiffy to help people and
                provide them a better web experience.</p>
              <p>Now, the time is to know about the Admin Details of this website, so, now we have to go down
                to know about Admin details.
              </p>
              <b>Admin's Statement for hopiffy</b>
              <p>As per my point of view, there are many people who visit the internet to get some information
                but 90% of the time they get wrong information so, the first priority of our website hopiffy is to
                provide 100% legit and accurate information to our users, Also, I hope my dream comes true
                one day, and our website will provide Original Content to provide a better user experience. So,
                From my Side thanks for visiting our website.
              </p>
              <b>Admin's Contact Information</b>
              <p>Hi, this hopiffy, in the above paragraph you know about the website properly and now I am going
                to provide my contact details.
                If you have any problem & suggestions for this website then you can contact me by using
                following contact details.
              </p>
              <p>Name-hopiffy</p>
              <a href="Email-hopiffy@gmail.com
">Email-hopiffy@gmail.com
              </a>
              <p>Facebook- hopiffy
              </p>
              <p>Twitter- hopiffy</p>
              <p>Instagram- hopiffy</p>
              <p>These details are my personal Account details if you want to contact me then you can contact
                me by the above platform.<br />
                Finally, this is our complete about us page about details are showing what is the motive to
                create hopiffy.
              </p>
              <p>If you want to contact us then you can email us at hopiffy@gmail.com.
                "Thanks for visiting our About Us Page</p>
            </div>
          </div>

          <div className="col-sm-4 mt-4">
            <div className="col-sm-12 follow_us p-2">
              <h4 className="follow_text">FOLLOW US</h4>
              <button className="icons_button1">
                <i className="fa-brands fa-instagram follow_icons"></i>
              </button>
              <button className="icons_button2">
                <i className="fa-brands fa-facebook follow_icons"></i>
              </button>
            </div>
            <div className="col-sm-12  most_popular p-4 mt-4">
              <h4 className="most_text">MOST POPULAR</h4>
              <hr />
              <div className="most_content">
                <div className="row">
                  <div className="col-sm-5 ">
                    <img
                      className="most_img" alt="imge"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU"
                    />
                  </div>
                  <div className="col-sm-6 ">
                    {" "}
                    <p>
                      Are Sirens Real? Gruesome Facts About Dark Siren Mermaid
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 ">
                    <img
                      className="most_img" alt="imge"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU"
                    />
                  </div>
                  <div className="col-sm-6 ">
                    {" "}
                    <p>
                      Are Sirens Real? Gruesome Facts About Dark Siren Mermaid
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 ">
                    <img
                      className="most_img" alt="imge"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU"
                    />
                  </div>
                  <div className="col-sm-6 ">
                    {" "}
                    <p>
                      Are Sirens Real? Gruesome Facts About Dark Siren Mermaid
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 ">
                    <img
                      className="most_img" alt="imge"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU"
                    />
                  </div>
                  <div className="col-sm-6 ">
                    {" "}
                    <p>
                      Are Sirens Real? Gruesome Facts About Dark Siren Mermaid
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12  p-2 mt-4 mb-4 categories">
              <h4 className="categories_text">Categories</h4>
              <ul className="categories_list">
                <li>Animals</li>
                <li>Culture&Mythology</li>
                <li>Entertainment</li>
                <li>History</li>
                <li>TECHNOLGY</li>
                <li>Travel</li>
                <li>Weird</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
