export const Route_Path = {
    HOME: "/",
    BLOG: "/blog",
    HISTORY: "history",
    ENTERTAINMENT: "entertainment",
    ANIMALS: "animals",
    WEIRD: "weird",
    TECHNOLOGY: "technology",
    MYTHOLOGY: "mythology",
    B1:"b1",
    ABOUT:"/about",
    PRIVACY:"/privacy",
    CONTACT: "/contact",
    TERMSANDCONDITION: "/Termsandcondition",
    DISCLAIMER: "/Disclaimer"
} 