import React from 'react'
import { Outlet } from 'react-router-dom';

const MainBlogPage = () => {
    return (
        <>
        <Outlet/>
        </>

    )
}

export default MainBlogPage;